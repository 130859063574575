import React, { useState, useEffect } from "react";
import Logo from "../../images/logo-white.png";
import Calender from "../../images/cal-white.png";
import Hour from "../../images/hour-white.png";
import Time from "../../images/time-white.png";
import India from "../../images/india.png";
import Back from "../../images/back.png";
import Mentor from "../../images/mentor.png";
import Registerform from "./register-form"
import { useLocation } from "react-router-dom";

function Register() {
  const location = useLocation();
  const fromPage = location.state?.from || "";
  useEffect(() => {
    window.sessionStorage.setItem("fromSource", window.sessionStorage?.getItem("fromMainSource") || "MC1")
  }, [])
  
 
  return (
    <div className="register-wrap">
     
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-lg-5 p-0">
            <div className="register-left">
              <a href="/mc1"  className="register-logo">
                <img src={Logo} alt="logo" className="img-fluid" width="160" height="60" />
              </a>
              <h1 className="reg-head">
                Maximise Happiness Through Career Mastery
              </h1>
              <div className="registration-info">
                <div className="reg-data">
                  <img src={Calender} alt="Calender" className="img-fluid reg-ico" width="20" height="16" />
                  <div className="reg-data-sub">11th August</div>
                </div>
                <div className="reg-data">
                  <img src={Time} alt="Calender" className="img-fluid reg-ico" width="20" height="16" />
                  <div className="reg-data-sub">04:00 PM</div>
                </div>
                <div className="reg-data">
                  <img src={Hour} alt="Calender" className="img-fluid reg-ico" width="20" height="10" />
                  <div className="reg-data-sub">2 hours</div>
                </div>
              </div>
              <div className="mentoredBy">
                <div className="bymentor">
                  Mentored By
                </div>
                <div className="mentor-data">
                  <div className="mentor-img">
                    <img src={Mentor} alt="Mentor" className="img-fluid mentor-image" width="50" height="50" />
                  </div>
                  <div className="mentor-details">
                    <div className="mentor-name">
                      Nikhar Arora
                    </div>
                    <div className="mentor-position">
                      Founder & CEO, Mentoria
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 p-0">
           <Registerform  fromPage={fromPage}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
