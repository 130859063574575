import React from 'react';
import Tc1 from "../../images/tc1.png";
import Tc2 from "../../images/tc2.png";
import Tc3 from "../../images/tc3.png";
import Tc4 from "../../images/tc4.png";
import Tc5 from "../../images/tc5.png";
function Reviews() {
    return (
        <div className='review-wraper mb-5 pb-3'>
            <div className="container">
                <h2 className="main-head">
                Hear From His Fans!
                </h2>
                <div className="row">
                    <div className="col-lg-4 mb-4 col-md-6">
                        <img src={Tc1} alt="Tc1" className='img-fluid d-block mx-auto' />
                    </div>
                    <div className="col-lg-4 mb-4 col-md-6">
                        <img src={Tc2} alt="Tc1" className='img-fluid d-block mx-auto' />
                    </div>
                    <div className="col-lg-4 mb-4 col-md-6">
                        <img src={Tc3} alt="Tc1" className='img-fluid d-block mx-auto' />
                    </div>
                    <div className="col-lg-4 mb-4 col-md-6">
                        <img src={Tc4} alt="Tc1" className='img-fluid d-block mx-auto' />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <img src={Tc5} alt="Tc1" className='img-fluid d-block mx-auto' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reviews;
