import React, { useEffect, useState } from 'react';
import Header from '../../layout/header-blue';
import About from './about';
import About1 from './about1';
import Banner from './banner';
import Corportepartner from './corporate-partners';
import Clientorg from './client-organization';
import Featured from './featured';
import Reviews from './reviews';
import Careerdiscovery from './career-discovery';
import TeamMember from './team-member';
import CareerCoach from './career-coach';
import FAQ from './faq';
import Footer from '../../layout/footer';
function Home() {
  const [time, setTime] = useState(15 * 60); // 15 minutes in seconds

  useEffect(() => {
    window.sessionStorage.removeItem("fromSource")
    window.sessionStorage.setItem("fromMainSource", "MC3")
    const interval = setInterval(() => {
      setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  

  // Format time as MM:SS
  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  
  useEffect(() => {
    window.sessionStorage.setItem("amount", ((formatTime(time) != "00:01") && (formatTime(time) != "00:00")) ? 299 : 499)
    window.sessionStorage.setItem("timing", formatTime(time))
  }, [formatTime(time)])

  const scrollToElementWithOffset = (id, offset) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };
  return (
    <div className='wrapper'>
            <Header/>
            <Banner/>
            <About/>
            <About1/>
            <Corportepartner/>
             <Featured/>
            <TeamMember />
            <Clientorg/>           
            <CareerCoach />
            <Reviews/>
            <Careerdiscovery/>
            <FAQ />
            <Footer />
    </div>
  );
}

export default Home;
