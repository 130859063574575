import React from "react";
import '../../css/bootstrap.min.css'
import '../../css/admin.css'
import '../../css/admin-responsive.css'
import Logo from '../../images/logo.png'
import { FaSignInAlt } from "react-icons/fa";

function AdminHeader() {
    const logOut = () => {
        window.sessionStorage.removeItem("user_id")
        window.sessionStorage.removeItem("username")
        window.sessionStorage.removeItem("roleName")
        window.location.href = '/login'
    }
    return (
        <div className="main_header">
            <div className="container">
                <div className="dashboard_header">
                    <div className="dashboard_logo py-3">
                        <a href="/"><img src={Logo} className="dashbaord_logoimg" alt="logo" /></a>
                    </div>
                    <div className="dashboard_headerlist">
                        <ul className="nav">
                            <li className="dashboard_list">
                                <span className="dashboard_admintext">Admin</span>
                                <FaSignInAlt className="dashboard_listsvg" />
                                <ul className="nav dashboard_subul">
                                    <li className="w-100" ><span className="submenu_litag"
                                    onClick={logOut}>Logout</span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminHeader;
