import React , {useState} from 'react';
import Calendar from '../../images/calenerpng.png'
import Clock from '../../images/clock.png'
import Online from '../../images/online.png'
import FastFillig from '../../images/fast_filling.png'
import BannerImg from '../../images/banner_group.png'
import Video1 from "../../video/Masterclass-1 .mp4";
import VideoIcon from "../../images/video.png";
function Banner() {
    const [controls, setControls] = useState(false);
    const clickVideo = ()=>{
        const video = document.getElementById('video1')
        if(video.paused) {
            setControls(true);
            document.getElementById("videoIcon").style.display = "none";
            video.play();
        }else {
            setControls(false);
            document.getElementById("videoIcon").style.display = "block";
            video.pause();
        }
    }
  return (
    <div className='banner'>
        <div className='container'>
            <div className='row flex-column-row'>
                <div className='col-lg-6'>
                    <h1 className='banner-heading'>Feeling stuck in your career?</h1>
                    <p className='banner-para'>Don't let Monday blues rule your life.
                    Join our MasterClass on Maximising Happiness Through Career Mastery to break free. Discover what will get you happiness, growth and money!</p>
                    <div className='banner-list'>
                        <div className='banner-list-item'>
                            <img src={Calendar} width={22} height={22} alt='calendar' className='img-fluid'/>
                            11th August, 4 PM
                        </div>
                        <div className='banner-list-item'>
                            <img src={Clock} width={22} height={22} alt='Clock' className='img-fluid'/>
                            2 hours 
                        </div>
                        <div className='banner-list-item'>
                            <img src={Online} width={22} height={22} alt='Online' className='img-fluid'/>
                            Online
                        </div>
                    </div>
                    <a href="/mc1/register" className='btn-theme'>Register Now</a>
                    <div className='banner_bar'>
                        <div className='banner_progress' style={{width: '80%'}}></div>
                    </div>
                    <div className='banner_book_div'><img src={FastFillig} width={28} height={35} className='img-fluid me-2'/>Fast Filling<span className='banner_line'></span> Limited Seats Remaining, <span className='banner_book'>Book NOW</span></div>
                </div>
                {/* <div className='col-lg-6'>
                    <img src={BannerImg} className='img-fluid' width="100%" height="100%" alt='banner'/>
                </div> */}
                 <div className="col-lg-6 mb-md-4">
                        {/* <img src={CareerCoachImg} alt="Meet Your Career Coach" className='img-fluid' /> */}
                        <div className='video-container'
                        onClick={() =>clickVideo()}  
                        >
                         
                            <video id="video1"
                                width="560"
                                height="415"
                                className="video-player"
                                controls={controls} 
                                preload="metadata"
                                style={{
                                    borderRadius: '15px',
                                    overflow: 'hidden'
                                }}
                            >
                                <source src={Video1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className='videoIcon' id="videoIcon">
                            <img src={VideoIcon} alt="VideoIcon" width="60" height="60" className='img-fluid' />
                        </div>

                        </div>


                        <div className='d-flex'>
                            <p className='c-coach-img-title'>“Your Coach Has an Important Message for You”</p>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  );
}

export default Banner;
