import React from 'react';
import CareerAdv from "../../images/career-advisor.png";
import Star from "../../images/star.png";
function Reviews() {
    return (
        <div className='career-wrap pb-4'>
            <div className="container">
                <div className="main-head main-head-half">
                    India’s Most Reliable Career Discovery Platform
                </div>
                <div className="row align-items-end align-items-md-start flex-column-row">
                    <div className="col-lg-6 mb-0 mb-lg-4">
                        <div className='career-advisor-desc'>
                            <p> According to Gallup’s 2024 report, 86% of Indian employees are struggling or suffering. They are stuck in the wrong jobs or wrong careers. They want to switch but don’t know how. And that’s a problem Mentoria is trying to solve.</p>
                           
                            <p>Mentoria is India’s most reliable and holistic career guidance and mentorship platform. We’ve enabled over 3 lakh people to get #CareerReady and partnered with 180+ corporates across India. Our mission is to help you find a job you will enjoy, excel and earn at.</p>
                            <div className='star-border star-wrap'>
                                <img src={Star} alt="Star" className='img-fluid' width="50" height="50"/>
                                <div className='d-flex flex-column ms-2'>                                  
                                    <div className='text-bold'>
                                        4.6/5
                                    </div>
                                    <div className='text-sm' style={{lineHeight:"18px"}}>
                                      Google Rating
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <img src={CareerAdv} alt="Tc1" className='img-fluid' width="" height=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reviews;
