import React from 'react';
import TeamMember1 from "../../images/fresher.png"
import TeamMember2 from "../../images/p1-5.png"
import TeamMember3 from "../../images/p5-10.png"
function CorporatePartners() {
    return (
        <div className='career-wrap'>
            <div className="container">
                <h2 className="main-head main-head3">
                Who are these Masterclasses for?
                </h2>
               <div className="row">
                 <div className="col-lg-4">
                    <div className="main-features">
                    <div className="col d-flex align-items-center">
                        <img className='t-m-profile' src={TeamMember1} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a className="t-m-heading">Freshers</a>
                        </div>                       
                    </div>
                    <p className='t-m-list-content pt-3'>Discover how to land your first job and build a solid foundation for a successful career.</p>
                    </div>
                 </div>
                 <div className="col-lg-4">
                    <div className="main-features">
                    <div className="col d-flex align-items-center">
                        <img className='t-m-profile' src={TeamMember2} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a className="t-m-heading">Professionals with 1-5 Years of Experience</a>
                        </div>                       
                    </div>
                    <p className='t-m-list-content pt-3'>Tired of trial and error? Ensure your next career move is the right one.</p>
                    </div>
                 </div>
                 <div className="col-lg-4">
                    <div className="main-features">
                    <div className="col d-flex align-items-center">
                        <img className='t-m-profile' src={TeamMember3} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a className="t-m-heading">Professionals with 5-10 Years of Experience</a>
                        </div>                       
                    </div>
                    <p className='t-m-list-content pt-3'>You’ve completed Phase 1 of your career. Learn how to launch Phase 2 and make the most of your experience.</p>
                    </div>
                 </div>
               </div>
            </div>
        </div>
    );
}

export default CorporatePartners;
