import React from 'react';
import Accenture from "../../images/accenture.png"
import Capgemini from "../../images/capgemini.png"
import Zomato from "../../images/zomato.png"
import Ibm from "../../images/ibm.png"
import Infosys from "../../images/infosys.png"
import Axisbank from "../../images/axis-bank.png"
import Bajaj from "../../images/bajaj.png"
import Amazon from "../../images/amazon.png"
import Ey from "../../images/ey.png"
import Vi from "../../images/vi.png"
import Crisil from "../../images/crisil.png"
import Myntra from "../../images/myntra.png"
function ClientOrg() {
    return (
        <div className='client-org-wrap mt-5'>
            <div className="container">
                <h2 className="main-head">
                Our Users Work at These Organisations
                </h2>
                <div className="parnters pb-5">
                    <div className="parnterimg">
                        <img src={Capgemini} alt="Capgemini" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Myntra} alt="Myntra" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Zomato} alt="Zomato" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Ibm} alt="Ibm" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Infosys} alt="Infosys" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Accenture} alt="Accenture" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Axisbank} alt="Axisbank" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Bajaj} alt="Bajaj" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Amazon} alt="Amazon" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Ey} alt="Ey" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div> 
                    <div className="parnterimg">
                        <img src={Vi} alt="Vi" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div> 
                    <div className="parnterimg">
                        <img src={Crisil} alt="Crisil" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div> 
                </div>
                
            </div>
        </div>
    );
}

export default ClientOrg;
