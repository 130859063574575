import React, { useState, useEffect } from "react";
import '../css/bootstrap.min.css'
import '../css/admin.css'
import '../css/admin-responsive.css'
import AdminHeader from "../admin/layout/header"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import * as XLSX from "xlsx";
import { apiCallingPost } from "../service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

function Listing() {
    const [activepage, SetActivepage] = useState(1)
    const [reportList, setReportList] = useState([])
    const [itemsCountPerPage, setItemsCountPerPage] = useState(10)
    const [totalItemsCount, setTotalItemsCount] = useState('')
    const [pageOffset, setPageOffset] = useState("0")
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [fromSource, setFromSource] = useState('');
    const [cmbSelect, setCmbSelect] = useState('');
    const [isEnabled, setIsEnabled] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isData, setIsData] = useState(true);
    useEffect(() => {
        if (!window.sessionStorage.getItem("user_id")) {
            window.location.href = "/login"
        }
        getReport();
    }, [])

    const handleExportClick = () => {
        getReportAllData();
    };

    const getReportAllData = () => {
        let postData = {
            functionName: "userPaymentExportReport",
            cmbSelect: cmbSelect,
            txtInput: fromSource,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
        }
        apiCallingPost(postData).then((data) => {
            if (data.data.success === "1") {
                setTotalItemsCount(data.data.totalCount)
                // console.log("contactResultReport---", data.data.result.contactResultReport);
                var dataArray = [];
                if (data.data.totalCount > 0) {
                    for (let i = 0; i < data.data.result.length; i++) {
                        let obj = {};
                        obj.from_source = data.data.result[i].from_source;
                        obj.userName = data.data.result[i].userName;
                        obj.emailId = data.data.result[i].emailId;
                        obj.mobileNo = data.data.result[i].mobileNo;
                        obj.date = data.data.result[i].date === null ? "" : moment(data.date).format('DD-MM-YYYY');
                        obj.amount = data.data.result[i].amount;
                        obj.currency = data.data.result[i].currency;
                        obj.paymentMethod = data.data.result[i].paymentMethod;
                        obj.paymentStaus = data.data.result[i].paymentStaus;
                        obj.trackingId = data.data.result[i].trackingId;
                        dataArray.push(obj);
                    }
                    let header = [
                        "From Source",
                        "User Name",
                        "Email Id",
                        "Mobile No.",
                        "Date",
                        "Amount",
                        "Currency",
                        "Payment Method",
                        "Payment Staus",
                        "Tracking Id"
                    ];
                    const columnWidths = [
                        { wch: 15 }, // Name
                        { wch: 12 }, // Mobile Number
                        { wch: 11 }, // Submitted On
                        { wch: 20 }, // Email Id
                        { wch: 20 }, // Enquire Type
                        { wch: 13 }, // State Name
                        { wch: 13 }, // City Name
                        { wch: 47 }, // Center Name
                        { wch: 19 }, // Product Category Name
                        { wch: 35 }, // Product Name
                    ];
                    const worksheet = XLSX.utils.json_to_sheet(dataArray);

                    worksheet["!cols"] = columnWidths;
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "mentoria-report");
                    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
                    XLSX.writeFile(workbook, `mentoria-report-${new Date(timestamp).toLocaleString('en-GB', { hour12: true }).replaceAll(",", "-").replaceAll("/", ".").replaceAll(":", ".")}.xlsx`, {
                        compression: true,
                    });
                }
                else {
                    toast.error('Sorry, no enquiry details found');
                }
            }
        });
    }
    const pageChange = (pageNumber) => {
        let offset = "0"
        SetActivepage(pageNumber)
        if (pageNumber == "1") {
            offset = "0";
        } else {
            offset = parseInt(pageNumber - 1) * itemsCountPerPage;
        }
        // SetActivepage(pageNumber)
        setPageOffset(offset)
        if (startDate && endDate) {
            if (startDate > endDate) {
                toast.error(' End date must be greater than or equal to start date');
                document.getElementById('todate').focus();
                return false;
            }
        }
        let postData = {
            functionName: "userPaymentReport",
            cmbSelect: cmbSelect,
            txtInput: fromSource,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
            offset: offset,
            max: itemsCountPerPage
        }
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result)
                // console.log('getUserAuthentication-- ', data.result)
                setTotalItemsCount(data.data.totalCount)
                setIsData(false);
            }
        })

    };
    const getReport = () => {
        let postData = {
            functionName: "userPaymentReport",
            cmbSelect: "",
            txtInput: "",
            fromDate: '',
            toDate: '',
            offset: pageOffset,
            max: itemsCountPerPage
        }
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result)
                // console.log('getUserAuthentication-- ', data.result)
                setTotalItemsCount(data.data.totalCount)
                setIsData(false);
            }
        })


    }


    const searchClickFunction = () => {
        SetActivepage(1)
        setPageOffset(0)
        if (startDate && endDate) {
            if (startDate > endDate) {
                toast.error('End date must be greater than or equal to start date');
                document.getElementById('todate').focus();
                return false;
            }
        }
        let postData = {
            functionName: "userPaymentReport",
            cmbSelect: cmbSelect,
            txtInput: fromSource,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
            offset: pageOffset,
            max: itemsCountPerPage
        }
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result)
                // console.log('getUserAuthentication-- ', data.result)
                setTotalItemsCount(data.data.totalCount)
            }
        })
    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    const fromSourceOnChange = (event) => {
        if (event.target.value == 0) {
            setIsEnabled(true);
            setCmbSelect("")
            setFromSource("");
        } else {
            setFromSource(event.target.value);
            setCmbSelect("fromSource")
            setIsEnabled(false);
        }
    };

    const clearSearch = () => {
        window.location.reload();
    };
    return (
        <div className="dashboard_wrapper">
            <ToastContainer />
            <AdminHeader />
            <div className="dash_breadcrumbs">
                {/* <nav aria-label="breadcrumb">
                    <div className="container">

                        <ol className="breadcrumb py-3">
                            <li className="breadcrumb-item"><a href="/dashboard" className="text-decoration-none">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Enquiry Details</li>
                        </ol>
                    </div>
                </nav> */}
                <div className="container px-2">
                    <div className="heading_wrapper_div my-3">
                        <h1 className="view_reportsheading text-trnfrmReport">Enquiry Details</h1>
                        {totalItemsCount > 0 &&
                            <div className=" ml-auto">
                                <span><strong>Total Enquiries : </strong>{totalItemsCount}</span>
                                <button className="ms-4 btn btn-primary py-1" onClick={handleExportClick}>
                                    Export Excel
                                </button>
                            </div>
                        }
                    </div>
                    <hr className="hr_line"></hr>


                    <div className="row mb-3 bg-white py-3">
                        <div className="col-md-2 col-lg-2 mb-lg-0 mb-3">
                            <select
                                className="inputField input-section p-0"
                                name="selectName"
                                value={fromSource}
                                onChange={fromSourceOnChange}
                            >
                                <option value="0">Search In</option>
                                <option value="MC1">MC1</option>
                                <option value="MC2">MC2</option>
                                <option value="MC3">MC3</option>
                            </select>
                        </div>
                        <div className="col-md-2 col-lg-2 mb-lg-0 mb-3">
                            <DatePicker
                                className="inputField input-section"
                                selected={startDate}
                                placeholderText="From Date"
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => setStartDate(date)}
                                id="fromdate"
                            />
                        </div>
                        <div className="col-md-2 col-lg-2 mb-lg-0 mb-3">
                            <DatePicker
                                className="inputField input-section"
                                selected={endDate}
                                placeholderText="To Date"
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => setEndDate(date)}
                                id="todate"
                            />
                        </div>

                        <div className="col-md-2 col-lg-1 col-6 mb-lg-0 mb-3 pe-lg-0">
                            <button className="input_form_bttn w-100" onClick={searchClickFunction}>
                                Search
                            </button>
                        </div>
                        <div className="col-md-2 pl-0 col-lg-1 col-6 mb-lg-0 mb-3 pe-lg-0">
                            <button className="btn btn-secondary py-1 w-100" onClick={clearSearch}>
                                Clear
                            </button>
                        </div>

                    </div>


                    <div className="react_pagination">
                        {totalItemsCount > itemsCountPerPage &&
                            <Pagination
                                activePage={activepage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={pageChange}
                            />
                        }
                    </div>

                    {isData === false && (
                        <div className="table-responsive mb-2 report_table_div bg-white">
                            {reportList.length > 0 ?
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sl. No.</th>
                                            <th>Source</th>
                                            <th>User Name</th>
                                            <th>Email Id</th>
                                            <th>Mobile No.</th>
                                            <th style={{ width: "100px" }}>Date</th>
                                            <th>Amount</th>
                                            <th>Payment Method</th>
                                            <th>Payment Staus</th>
                                            <th>Tracking Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportList.map((data, index) => (
                                            <tr key={index}>
                                                <td>{(pageOffset * 1) + index + 1}</td>
                                                <td>{data.from_source}</td>
                                                <td>{data.userName}</td>
                                                <td>{data.emailId}</td>
                                                <td>{data.mobileNo}</td>
                                                <td>
                                                    {data.date === null ? "" : moment(data.date).format('DD-MM-YYYY')
                                                    }</td>
                                                <td>{data.amount}
                                                    <span>  {data.currency}</span>
                                                </td>
                                                <td>{data.paymentMethod}</td>
                                                <td>{data.paymentStaus === "Payment Completed" ?
                                                    <span className="text-success">{data.paymentStaus}</span>
                                                    :<span className="text-danger">{data.paymentStaus}</span>
                                                }
                                                </td>
                                                <td>{data.trackingId}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                :
                                <div class="alert alert-danger text-center" role="alert">
                                    Sorry, no details found
                                </div>
                            }

                        </div>
                    )}

                    <div className="react_pagination">
                        {totalItemsCount > itemsCountPerPage &&
                            <Pagination
                                activePage={activepage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={pageChange}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Listing;
