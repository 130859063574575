import React, { useState, useRef, useEffect } from 'react';
import Tc1 from "../../images/pritam.PNG";
import Tc2 from "../../images/bodhisatta.PNG";
import Tc3 from "../../images/abhishek.PNG";
import Tc4 from "../../images/tc4.png";
import Tc5 from "../../images/tc5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Video1 from "../../video/Masterclass-1 .mp4";
import Video2 from "../../video/Bodhisatta Chatterjee_1.mp4";
import Video3 from "../../video/Abhishek Tadimari.mp4";
import Video4 from "../../video/Pritam Sarkar.mp4";
import VideoIcon from "../../images/video.png";

function Reviews() {
    const [currentVideoId, setCurrentVideoId] = useState(null);
    const [isPlaying, setIsPlaying] = useState({}); // Track which video is playing
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track if on mobile
    const videoRefs = useRef({});  // Store video refs

    // Update mobile state on window resize
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleVideoIconClick = (videoId) => {
        // Pause all videos first
        Object.keys(videoRefs.current).forEach((id) => {
            if (videoRefs.current[id]) {
                videoRefs.current[id].pause();
            }
        });

        // Set the current video ID and play the corresponding video
        setCurrentVideoId(videoId);
        setIsPlaying((prev) => ({ ...prev, [videoId]: true })); // Set this video as playing
        if (videoRefs.current[videoId]) {
            videoRefs.current[videoId].play();
        }
    };

    const handleVideoPlay = (videoId) => {
        setIsPlaying((prev) => ({ ...prev, [videoId]: true }));
    };

    const handleVideoPause = (videoId) => {
        setIsPlaying((prev) => ({ ...prev, [videoId]: false }));
    };

    // Array of slides (could be dynamically generated)
    const slides = [
        { video: Video2, poster: Tc2, videoId: "video2" },
        { video: Video4, poster: Tc1, videoId: "video3" },
        { video: Video3, poster: Tc3, videoId: "video1" }
    ];

    // Slick slider settings, dots and arrows conditionally based on number of slides
    const settings = {
        dots: slides.length > 3, // Show dots if more than 3 slides
        infinite: false,
        speed: 500,
        arrows: slides.length > 3, // Show arrows if more than 3 slides
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='review-wraper mb-5 pb-3'>
            <div className="container">
                <h2 className="main-head">Hear From His Fans!</h2>
                <div>
                    <Slider {...settings} className='slider'>
                        {slides.map((slide, index) => (
                            <div key={index}>
                                <div className='video-container'>
                                    <video
                                        ref={(el) => (videoRefs.current[slide.videoId] = el)}
                                        width="560"
                                        height="315"
                                        className={`video-player ${isPlaying[slide.videoId] ? 'playing' : ''}`} // Dynamically add 'playing' class
                                        controls={currentVideoId === slide.videoId}
                                        preload="metadata"
                                        poster={slide.poster}
                                        style={{
                                            borderRadius: '15px',
                                            overflow: 'hidden'
                                        }}
                                        onPlay={() => handleVideoPlay(slide.videoId)}
                                        onPause={() => handleVideoPause(slide.videoId)}
                                    >
                                        <source src={slide.video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    {!isPlaying[slide.videoId] && !(isMobile && currentVideoId === slide.videoId) && (
                                        <div className='videoIcon' onClick={() => handleVideoIconClick(slide.videoId)}>
                                            <img src={VideoIcon} alt="VideoIcon" width="60" height="60" className='img-fluid' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Reviews;
