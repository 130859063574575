import React from 'react';
import MeterImg from '../../images/meter.png'
import CareerImg01 from '../../images/career01.png'
import CareerImg02 from '../../images/career02.png'
import CareerImg03 from '../../images/career03.png'
import CareerImg04 from '../../images/career04.png'
import CareerImg05 from '../../images/career05.png'
function CareerMastery() {
  return (
    <div className='career-mastery'>
        <div className='container'>
            <h3 className="main-head">Why Attend our MasterClass on Maximising Happiness Through Career Mastery</h3>
            <div className='career_details'>
                <div className='career_inner'>
                    <div className='career_inner_left'>
                        <div className='career_head'>Check Your Career Health Score</div>
                        <p className='main-para'>Find out what's making you unhappy in your
                        career.</p>
                    </div>
                    <div className='career_inner_right'>
                        <img src={MeterImg} width="auto" height="auto" alt='meter' className='img-fluid'/>
                    </div>
                </div>
                <div className='career_inner'>
                    <div className='career_inner_left'>
                    <img src={CareerImg01} width="auto" height="auto" alt='meter' className='img-fluid'/>
                    </div>
                    <div className='career_inner_right'>
                        
                        <div className='career_head'>Spot the Symptoms</div>
                        <p className='main-para'>Monday Blues? Horrible boss? Bad job market?
What's making you hate your career? And how do
we fix it?</p>
                    </div>
                </div>
                <div className='career_inner'>
                    <div className='career_inner_left'>
                        <div className='career_head'>Understand the Causes</div>
                        <p className='main-para'>Break down popular beliefs about careers. An
MBA won't always get you a hike. Switches won't
get you more money. Discover what will.</p>
                    </div>
                    <div className='career_inner_right'>
                        <img src={CareerImg02} width="auto" height="auto" alt='meter' className='img-fluid'/>
                    </div>
                </div>
                <div className='career_inner'>
                    <div className='career_inner_left'>
                    <img src={CareerImg03} width="auto" height="auto" alt='meter' className='img-fluid'/>
                        
                    </div>
                    <div className='career_inner_right'>
                    <div className='career_head'>What’s the Issue - Job or Career?</div>
                        <p className='main-para'>Figure out where the bigger problem lies - we’ll give you a full-proof plan to solve both.</p>
                    </div>
                </div>
                <div className='career_inner'>
                    <div className='career_inner_left'>
                        <div className='career_head'>Solving the Problem</div>
                        <p className='main-para'>Here comes the action plan! Get to know exactly
what you need to do to find a pathway that
works for you. We'll help you build this plan.
</p>
                    </div>
                    <div className='career_inner_right'>
                        <img src={CareerImg04} width="auto" height="auto" alt='meter' className='img-fluid'/>
                    </div>
                </div>
                <div className='career_inner'>
                    <div className='career_inner_left'>
                    <img src={CareerImg05} width="auto" height="auto" alt='meter' className='img-fluid'/>
                       
                    </div>
                    <div className='career_inner_right'>
                    <div className='career_head'>Get Insider Info</div>
                        <p className='main-para'>We're bringing experts to share the secret
formula of career success! You don't want to miss
the scoop they got.</p>
                    </div>
                </div>
            </div>
            {/* <div className='d-flex justify-content-center py-5'>
               <div className='career-btn-div mt-0'>
               <a href="/mc1/register" className='btn-theme'>Register Now</a>
               </div>
               </div> */}
        </div>
    </div>
  );
}

export default CareerMastery;
