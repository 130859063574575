import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from '../../images/logo.png'
import '../../css/bootstrap.min.css'
import '../../css/style.css'
import '../../css/responsive.css'
import XImg from '../../images/x.png'
import linkedImg from '../../images/linkedIn.png'
import instagramImg from '../../images/instagram.png'
import facebookImg from '../../images/facebook.png'
import Mail from '../../images/mail.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiCallingPost } from "../../service";

function Thankyou() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const createdOn = location?.state?.timestamp || ""
    if(createdOn !== "") {    
      //document.getElementById("loader-reg").style.display="flex";
      if(location?.state?.razorpay_payment_id){
        let razorpayPostData = {
          functionName: "razorPay",
          PaymentKeyId: location?.state?.razorpay_payment_id || "",
          createdOn: location?.state?.timestamp,
          userId: location?.state?.userCreationData.userId
        }
        apiCallingPost(razorpayPostData).then((razorPayResult) => {
          if (razorPayResult.data.success === "1") {
            const razorPayData = razorPayResult.data.result;
            let razorpaymentPostData = {
              functionName: "razorPayment",
              createdOn: location?.state?.timestamp,
              userId: location?.state?.userCreationData.userId,
              razorPaymentId: razorPayData.razorPaymentId,
              userPaymentId: location?.state?.userCreationData.userPaymentId,
              paymentJsonData: razorPayData.razorPayData
            }
            apiCallingPost(razorpaymentPostData).then((razorPaymentData) => {
              if (razorPaymentData.data.success === "1") {
                setTimeout(() => {
                  navigate("/mc2")
                }, 6000);
              }
              else {
                toast.error(razorPaymentData.data.errorMessage)
              }
            });
          }
        })
      }
    }
    else {
      navigate("/mc2")
    }

  }, [])
  
  return (
    location?.state?.timestamp &&
    <div className="thankyou-wrap">
      <ToastContainer />
      <div className='header'>
        <div className='container'>
          <a  href="/mc2" >
            <img src={Logo} width={145} height={55} alt='logo' className='img-fluid' />
          </a>
        </div>
      </div>
      <div className="thankyou-body">
        <div className="thankyou col-lg-10 m-auto col-md-8 col-10 col-xl-5">
          <div className="thank-img">
            <img src={Mail} alt="Mail" className="img-fluid" width="150" height="150" />
          </div>
          {location?.state?.razorpay_payment_id ?
            <>
              <div className="thank-head">Thank You for Registering</div>
              <div className="thank-desc">You’re all set! An email confirmation will be sent to you shortly. We look forward to seeing you there</div>
            </>
            : 
            <div>
              <div className="thank-head">Payment was unsuccessful. </div>
              <div className="thank-desc mb-2">Please try again. </div>
              <a href="/mc2" class="btn-theme btn-sm">Back to Home</a>
            </div>
          }
        </div>
      </div>
      <div className='footer py-4 thank-you-footer'>
        <div className='container'>
          <div className='row justify-content-center pt-5'>
            <div className='col-md-6'>
              <p className='copy-right'>Mentoria | 2024 All Rights Reserved</p>
            </div>
            <div className='col-md-6'>
            <nav className="nav justify-content-md-end nav-center-sm">
              <span className="nav-link ps-0">Connect with us</span>
              <a className="nav-link" target="_blank" aria-current="page" 
              href="https://www.facebook.com/mymentoria?mibextid=ZbWKwL">
                <img src={facebookImg} alt="Facebook" width={20} height={20} className="img-fluid"></img>
              </a>
              <a className="nav-link" target="_blank" 
              href="https://www.instagram.com/mymentoria?igsh=YWJyY2tod2I3d2oy">
                <img src={instagramImg} alt="Instagram" width={20} height={20}></img>
              </a>
              <a className="nav-link" target="_blank" 
              href="https://www.linkedin.com/company/mentoria/">
                <img src={linkedImg} alt="Linked In" width={20} height={20}></img>
              </a>
              <a className="nav-link" target="_blank" href="https://x.com/mymentoria">
                <img src={XImg} alt="Icon" width={20} height={20}></img>
              </a>
            </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="loader-wrapper" id="loader-reg">
        <div className="loader">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;
