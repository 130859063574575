import React from 'react';
import Health from "../../images/cost-calculator.png";
import Problem from "../../images/health.png";
import Career from "../../images/interview-question.png";
import Skill from "../../images/skill.png";
import Calculator from "../../images/calculator.png";
function CareerTools() {
    return (
        <div className='career-tools-wrapper'>
            <div className="container">
                <h2 className="main-head col-lg-10 text-center mx-auto">
                {/* Unlock Exclusive Job Hunting Tools through this Masterclass */}
                Unlock Exclusive Job Hunting Tools Worth INR 7000
                </h2>
               <div className="career-tools">
                <div className="tools">
                    <div className="toolimage">
                        <img src={Health} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Job Unhappiness Cost Calculator: <span className='inr-cut'> INR 1,000     </span>
              
                    </div>
                    <div className="tooldesc">
                    Stuck in a job you hate? Find out how much it's costing you and why to make a change.
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Problem} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Job Application Tracker: <span className='inr-cut'> INR 2,500     </span><br/>
                    </div>
                    <div className="tooldesc">
                    No more losing track! Keep all your job applications and next steps in one easy-to-use tool.
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Career} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Interview Question Bank: <span className='inr-cut'> INR 1,500     </span>
                    </div>
                    <div className="tooldesc">
                    Hate questions you didn’t prepare for? No more! Get access to the complete variety of questions interviewers ask.
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Skill} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Job Offer Rater: <span className='inr-cut'> INR 1,500     </span>
                    </div>
                    <div className="tooldesc">
                    After this Masterclass, you'll have so many job offers that you'll need this tool to choose the best one.
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Calculator} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Lost Income Calculator: <span className='inr-cut'> INR 500     </span>
                    </div>
                    <div className="tooldesc">
                    Every day in the wrong job costs you money. Find out how much before looking for your dream job
                    </div>
                </div>
               </div>
               <div className='d-flex justify-content-center py-5'>
               <div className='career-btn-div'>
               <a href="/mc2/register" className='btn-theme'>Unlock for Free</a>
               </div>
               </div>
              
            </div>
        </div>
    );
}

export default CareerTools;
