import React from 'react';
import '../css/bootstrap.min.css'
import '../css/style.css'
import '../css/responsive.css'
import Logo from '../images/logo.png'
function Header() {
  return (
    <div className='header'>
        <div className='container'>
            <img src={Logo} width={145} height={55} alt='logo' className='img-fluid'/>
        </div>
    </div>
  );
}

export default Header;
