import React, { useEffect, useState } from 'react';
import Header from '../../layout/header';
import About from './about';
import Banner from './banner';
import Corportepartner from './corporate-partners';
import CareerMastery from './career-mastery';
import CareerTools from './career-tools';
import Clientsays from './client-says';
import Clientorg from './client-organization';
import Featured from './featured';
import Reviews from './reviews';
import Careerdiscovery from './career-discovery';
import TeamMember from './team-member';
import CareerCoach from './career-coach';
import FAQ from './faq';
import Footer from '../../layout/footer';
import HomeAds from './home-ad.jsx'
function Home() {
  const [time, setTime] = useState(15 * 60); // 15 minutes in seconds

  useEffect(() => {
    window.sessionStorage.removeItem("fromMainSource")
    window.sessionStorage.setItem("fromSource", "MC1")
    const interval = setInterval(() => {
      setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  

  // Format time as MM:SS
  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  
  useEffect(() => {
    window.sessionStorage.setItem("amount", ((formatTime(time) != "00:01") && (formatTime(time) != "00:00")) ? 299 : 499)
    window.sessionStorage.setItem("timing", formatTime(time))
  }, [formatTime(time)])

  return (
    <div className='wrapper'>
            <Header/>
             <Banner/>
            <Featured/>
            <TeamMember />
            <CareerCoach />
            
            <Reviews/>
            <Clientorg/>
            
            <CareerMastery/>
            
           
            <Careerdiscovery/>
            
            <CareerTools/>
           
            <About/>
            <Corportepartner/>
            {/* <Clientsays/> */}
        
          
            {/* <Featured/> */}
           
            <FAQ />
            <Footer />
            <HomeAds/>
    </div>
  );
}

export default Home;
