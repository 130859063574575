import React from 'react';
import hiImg from '../../images/hi.png'
import eyeImg from '../../images/eye.png'
import manImg from '../../images/man.png'
import penguinImg from '../../images/penguin.png'
import stopImg from '../../images/stop.png'
import trpphyImg from '../../images/trpphy.png'
import warningImg from '../../images/warning.png'
import flagImgImg from '../../images/flag.png'
function PossibleWay() {
  return (
    <div className='posible-way mt-md-5 mb-5'>
      <div className='container'>
        <h2 className="main-head">
          Why Attend our Masterclass on Landing Your Dream Job in the Best Possible Way
        </h2>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={hiImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Saying Bye to Popular Job Search Myths
          </h6>
          <p className='main-para'>Everyone has some advice or ‘hacks’ when it comes to job search. We’ll tell you which ones to believe and which ones to ignore.</p>
        </div>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={flagImgImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Building a Job-Winning CV
          </h6>
          <p className='main-para'>How? You’ll learn the tools HRs use and apply them to your advantage.</p>
        </div>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={warningImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Warning! Your Cover Letter is Missing
          </h6>
          <p className='main-para'>78% of recruiters choose candidates with a cover letter. We’ll show you how to land a job with a cover letter alone.</p>
        </div>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={trpphyImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Secret Weapon for a Killer LinkedIn Profile
          </h6>
          <p className='main-para'>Everyone has a LinkedIn profile, but is it strong enough to attract recruiters? Yours will be!</p>
        </div>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={stopImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Stop Hitting ‘Apply’ Everywhere
          </h6>
          <p className='main-para'>Applied to hundreds of jobs without getting a callback? Your phone won’t stop ringing after this segment.</p>
        </div>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={penguinImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Interviews Won’t be Scary After This
          </h6>
          <p className='main-para'>Does the thought of an interview give you nightmares? We can change that. We’ll turn you into an interview expert.</p>
        </div>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={eyeImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Secretly Applying to Jobs?
          </h6>
          <p className='main-para'>Don’t want your current boss to know about your job search? Learn how to apply secretly.</p>
        </div>
        <div className='possible-div'>
          <h6 className='head-last'>
            <span className='possible-span'>
              <img src={manImg} width={24} height={24} alt='hi' className='img-fluid' />
            </span>
            Become a Negotiation Ninja
          </h6>
          <p className='main-para'>We know you deserve a big package. Now it’s time to convince your employer. </p>
        </div>
        {/* <div className='d-flex justify-content-center py-5'>
               <div className='career-btn-div mt-0'>
               <a href="/mc2/register" className='btn-theme'>Register Now</a>
               </div>
               </div> */}
      </div>
    </div>
  );
}

export default PossibleWay;
