import React, { useState, useEffect } from 'react';
import '../css/bootstrap.min.css'
import '../css/admin.css'
import '../css/admin-responsive.css'
import Logo from '../images/logo-white.png'
import { apiCallingPost } from "../service";
import CryptoJS from 'crypto-js';

function Login() {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setloginError] = useState("")
    const [loginErrorFlag, setloginErrorFlag] = useState(false)


    const setLoginHandler = (e) => {
        let loginKey = "#$ME$%NT#$OR$#IA";
        if (userName === "") {
            setloginError("Username required")
            setloginErrorFlag(true)
        }
        else if (password === "") {
            setloginError("Password required")
            setloginErrorFlag(true)
        }
        else {
            const token = CryptoJS.AES.encrypt(JSON.stringify({ username: userName, password: password }), loginKey).toString();
            setloginErrorFlag(false)
            let postData = {
                functionName: "login",
                token: token
            }
            apiCallingPost(postData).then((data) => {
                if (data.data.success == "1") { 
                    window.sessionStorage.setItem("user_id", data.data.result.userData[0].userId)
                    window.sessionStorage.setItem("username", data.data.result.userData[0].username)
                    window.sessionStorage.setItem("roleName", data.data.result.userData[0].roleName)
                    window.location.href = '/listing'
                }
                else {
                    setloginError("Username Or Password Is InValid.")
                    setloginErrorFlag(true)
                }
            })
        }
    }
  return (
    <div className='login_wrapper'>
            <div className='left_login'>
                <div className='login_logo'>
                    <img src={Logo} className="img_logo img-fluid" alt="logo" width="300" heigh="200"/>
                </div>
                <h1 className='login_header'>Unlock Career Happiness and Land Your Dream Job!</h1>
            </div>
            <div className='right_login'>
                <div className='right_login_inner'>
                    <h2 className='right_loginhead'>Login</h2>
                    {loginErrorFlag === true &&
                        <label id="loginError" className="inputError"> {loginError} </label>
                    }
                    <div className="form_div">
                        <input
                            type='text'
                            id="username"
                            placeholder="Username"
                            className='form_login'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className="form_div">
                        <input
                            type='password'
                            id="password"
                            placeholder="Password"
                            className='form_login'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button
                        type='submit'
                        className='login_btn'
                         onClick={() => setLoginHandler()}
                    >  Login
                    </button>
                </div>
            </div>
        </div>
  );
}

export default Login;
