import React from 'react';
import Parnter1 from "../../images/job.png";
import Parnter4 from "../../images/Hr.png";
import Parnter3 from "../../images/google.png";
import Parnter2 from "../../images/luck.png";
function Featured() {
    return (
        <div className='features-wraper'>
            <div className="container">
                <h2 className="main-head">
                {/* You shouldn't attend this Masterclass if */}
                Should You Attend This Masterclass?
                <div className='main-head-sub'>
                You probably <span className='text-bold'>shouldn't</span> join if
                </div>
                </h2>
               
                <div className='featured-div'> 
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter1} alt="Userimg" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">                            
                            <div className='feature-digit-small'>
                            {/* You can get a job easily - no time or effort needed */}
                            Getting a job is super easy for you – 
                            no time or effort needed
                            </div>
                        </div>
                        
                    </div>
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter4} alt="Parnter1" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-small'>
                            {/* You know how to think like an HR */}
                            You already 
know exactly what
 HRs want
                            </div>
                        </div>
                        
                    </div>
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter3} alt="Parnter2" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-small'>
                            {/* You trust Google or Instagram to give you job search advice */}
                            You trust Google/Instagram for the best job search advice
                            </div>
                        </div>
                        
                    </div>
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter2} alt="Parnter2" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-small'>
                            You’ll just try your luck and apply, apply till you get a reply
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='d-flex justify-content-center py-5'>
               <div className='career-btn-div mt-0'>
               <a href="/mc2/register" className='btn-theme'>Register Now</a>
               </div>
               </div>
            </div>
        </div>
    );
}

export default Featured;
