import React from 'react';
import Parnter1 from "../../images/job.png";
import Parnter2 from "../../images/college.png";
import Parnter3 from "../../images/job1.png";
import Parnter4 from "../../images/job2.png";
function Featured() {
    return (
        <div className='features-wraper'>
            <div className="container">
                <h2 className="main-head">
                You shouldn't attend this Masterclass if
                </h2>
                <div className='featured-div'> 
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter1} alt="Userimg" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">                            
                            <div className='feature-digit-small'>
                            You love your current job
                            </div>
                        </div>
                        
                    </div>
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter4} alt="Parnter1" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-small'>
                            You’re waiting for appraisals to decide next steps
                            </div>
                        </div>
                        
                    </div>
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter2} alt="Parnter2" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-small'>
                            You paid a lot for college and don’t want to waste that money
                            </div>
                        </div>
                        
                    </div>
                    <div className="features-sub">
                        <div className="img-feature">
                            <img src={Parnter3} alt="Parnter2" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-small'>
                            You’re okay with hating your job every day for the rest of your life
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='d-flex justify-content-center py-5'>
               <div className='career-btn-div mt-0'>
               <a href="/mc1/register" className='btn-theme'>Register Now</a>
               </div>
               </div>
            </div>
        </div>
    );
}

export default Featured;
