import React from 'react';
import Userimg from "../../images/user.png";
import Parnter1 from "../../images/parnters.png";
import Parnter2 from "../../images/govt-parnterns.png";
function About() {
    return (
        <div className='about-wrap'>
            <div className="container">
                <h2 className="main-head">
                    Here's How Mentoria's Enabling India to get #CareerReady
                </h2>
                <div className='about-features'> 
                    <div className="features">
                        <div className="img-feature">
                            <img src={Userimg} alt="Userimg" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-large'>
                            3 Lakh+
                            </div>
                            <div className='feature-digit-small'>
                            Users
                            </div>
                        </div>
                        
                    </div>
                    <div className="features">
                        <div className="img-feature">
                            <img src={Parnter1} alt="Parnter1" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-large'>
                            180+
                            </div>
                            <div className='feature-digit-small'>
                            Corporate
                            Partners
                            </div>
                        </div>
                        
                    </div>
                    <div className="features">
                        <div className="img-feature">
                            <img src={Parnter2} alt="Parnter2" width="148" height="150" className='abt-feauture-img'/>
                        </div>
                        <div className="desc-feature">
                            <div className='feature-digit-large'>
                            17+
                            </div>
                            <div className='feature-digit-small'>
                            Government
                            Partners
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
