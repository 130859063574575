import React from 'react';
import Userimg from "../../images/3-feature.png";
import img1 from "../../images/health.png";
import img2 from "../../images/cost-calculator.png";
import img3 from "../../images/interview-question.png";
import img4 from "../../images/skill.png";
import img5 from "../../images/calculator.png";
function About() {
    return (
        <div className='about-wrap pt-0 mt-0'>
            <div className="container">               
                <div className="aboutwrap-3 card">
                    <div className="row card-body">
                       
                        <div className="col-lg-7 m-auto">
                            <div className='abtwrap3-tophead'>
                            Land Your Dream Job in the Best Possible Way
                            </div>
                            <div className='abtwrap3-mainhead'>
                            Struggling to find a job? Learn proven HR strategies to get more interview calls and the best job offers.
                            </div>
                            <div>
                                <a href="/mc2/register" className='btn-theme w-auto d-inline-block mt-md-5 mt-3 px-5'>Register Now</a>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center">
                            <img src={Userimg} alt="Userimg" className='img-fluid mt-md-0 mt-3'
                                width="300" height="300" />
                        </div>
                        <hr className='my-5' />
                        <div className='row m-0'>
                            <div className="abtwrap3-head3">Exclusive Tools you’ll get</div>
                            <div className="col-lg-12 m-auto">
                                <div className="d-md-flex gap-3 align-items-center justify-content-center flex-wrap-abt">
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img2} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                        Job 
                                        Unhappiness Cost Calculator
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img1} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                        Job
                                        Application Tracker
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img3} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                        Interview Question Bank
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img4} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                        Job Offer
                                        Rater
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img5} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                        Lost Income
                                        Calculator
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
