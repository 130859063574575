import React , {useState} from 'react';
import CareerCoachImg from "../../images/career-3.png";

function CareerCoach() {
    const [controls, setControls] = useState(false);
    const clickVideo = ()=>{
        const video = document.getElementById('video1')
        if(video.paused) {
            setControls(true);
            document.getElementById("videoIcon").style.display = "none";
            video.play();
        }else {
            setControls(false);
            document.getElementById("videoIcon").style.display = "block";
            video.pause();
        }
    }
    return (
        <div className='career-wrap pb-5 bg-white'>
            <div className="container">
                <h2 className="main-head"> Meet Your Career Coach </h2>
                <div className="row">
                    <div className="col-lg-6 mb-md-4 order-1 order-lg-2">
                        <img src={CareerCoachImg} alt="Meet Your Career Coach" className='img-fluid' width="570" height="578"/>
                       
                    </div>
                    <div className="col-lg-6 mb-md-4 order-2 order-lg-1 pe-lg-4">
                        <div className='career-advisor-desc'>
                            <p>Nikhar Arora is the Founder and CEO of Mentoria, India’s leading career mentorship platform for working professionals. He has helped 3 lakh professionals leave unhappy jobs and find fulfilling careers.</p>
                            <p>Nikhar's own career journey was full of twists and turns, which made him realise the need for proper career guidance in India. He has made it his mission to provide the guidance people need to grow and succeed.</p>
                            <p className='d-none d-xl-block'>
                            Nikhar has worked with top ad agencies like Ogilvy and DDB Mudra, and raised funds from leading investors and VCs.</p>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-0 mb-lg-4  order-3">
                        <div className='career-advisor-desc'>
                            <p>He has partnered with over 150 educational institutes, including Symbiosis, Bharati Vidyapeeth, and St. Xavier's, and collaborated with 17+ governments and foundations to spread career literacy across India.</p>
                            <p>He has also mentored professionals and students from top B-Schools like ISB, MICA, and IIM. Join Nikhar’s exclusive MasterClasses to learn how he helps people find success in their dream careers!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CareerCoach;
