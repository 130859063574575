import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage1 from "./pages/landing_1";
import HomePage2 from "./pages/landing_2";
import Home from "./pages/common-landing";
import RegisterLanding1 from "./pages/landing_1/register";
import RegisterLanding2 from "./pages/landing_2/register";
import ThankyouLanding1 from "./pages/landing_1/thank-you";
import ThankyouLanding2 from "./pages/landing_2/thank-you";
import Login from "./admin/login";
import Listing from "./admin/listing";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}></Route>  
        <Route path="/mc3" element={<Home/>}></Route>   
        <Route path="/mc1" element={<HomePage1 />}></Route>   
        <Route path="/mc2" element={<HomePage2 />}></Route>  
        <Route path="/login" element={<Login />}></Route>
        <Route path="/listing" element={<Listing />}></Route> 
        <Route path="mc1/register" element={<RegisterLanding1 />}></Route> 
        <Route path="mc2/register" element={<RegisterLanding2 />}></Route>     
        <Route path="mc1/thankyou" element={<ThankyouLanding1 />}></Route>     
        <Route path="mc2/thankyou" element={<ThankyouLanding2 />}></Route>      
      </Routes>
    </BrowserRouter>
  );
}

export default App;
