import React ,{useState}from 'react';
import CareerAdv from "../../images/career-advisor.png";
import Star from "../../images/star.png";
import Video1 from "../../video/Masterclass 2 .mp4";
import VideoIcon from "../../images/video.png";
import CareerCoachImg from "../../images/career-3.png";
function CareerCoach() {
    const [controls, setControls] = useState(false);
    const clickVideo = ()=>{
        const video = document.getElementById('video1')
        if(video.paused) {
            setControls(true);
            document.getElementById("videoIcon").style.display = "none";
            video.play();
        }else {
            setControls(false);
            document.getElementById("videoIcon").style.display = "block";
            video.pause();
        }
    }
    return (
        <div className='career-wrap pb-5'>
            <div className="container">
                <h2 className="main-head"> Meet Your Career Coach </h2>
                <div className="row">
                    <div className="col-lg-6 mb-md-4 order-1 order-lg-2">
                        <img src={CareerCoachImg} alt="Meet Your Career Coach" className='img-fluid career-right-img' height="100%" />
                        {/* <div className='video-container'
                        onClick={() =>clickVideo()}  
                        >
                         
                            <video id="video1"
                                width="560"
                                height="315"
                                className="video-player"
                                controls={controls} 
                                preload="metadata"
                                style={{
                                    borderRadius: '15px',
                                    overflow: 'hidden'
                                }}
                            >
                                <source src={Video1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className='videoIcon' id="videoIcon">
                            <img src={VideoIcon} alt="VideoIcon" width="60" height="60" className='img-fluid' />
                        </div>

                        </div>
                        <div className='d-flex'>
                            <p className='c-coach-img-title'>“Your Coach Has an Important Message for You”</p>
                        </div> */}
                    </div>
                    <div className="col-lg-6 mb-md-4 order-2 order-lg-1 pe-lg-4">
                        <div className='career-advisor-desc'>
                            {/* <p>Nikhar Arora is the Founder and CEO of Mentoria, India’s leading career mentorship platform for working professionals. He has helped 3 lakh professionals leave unhappy jobs and find fulfilling careers.</p>
                            <p>After interactions with thousands of working professionals and HR Teams across India’s 180+ top corporates,
                            he knows exactly what it takes to land the dream job - and is all set to share his learnings with you!</p>
                            <p className='d-none d-xl-block'>Nikhar has worked with top ad agencies like Ogilvy and DDB Mudra, and raised funds from leading investors and VCs.</p>  */}
                            <p>
                            Hey there! I’m Nikhar Arora, the Founder and CEO of Mentoria, India’s leading career mentorship platform. Over 3 lakh professionals have turned their careers around with our help, moving from jobs that drained them to ones that excite them every day.
                            </p>
                            <p>
                            After talking to thousands of professionals and HR teams across India’s top 180+ companies, I know exactly what it takes to land a dream job. Now, I’m ready to share my secrets with you!
                            </p>
                            <p>
                            I’ve worked with top-notch ad agencies like Ogilvy and DDB Mudra and raised funds from big-name investors. Along the way, I’ve also partnered with over 120 corporates like Accenture, L&T, ITC, and Britannia, and teamed up with 17+ governments and foundations to spread career literacy far and wide. Plus, I’ve had the joy of mentoring bright minds from top B-schools like ISB, MICA, and IIM.  
                            </p>




                        </div>
                    </div>
                    <div className="col-lg-12 mb-0 mb-lg-4  order-3">
                        <div className='career-advisor-desc'>
                            {/* <p>
                            He has partnered with over 150 educational institutes, including Symbiosis, Bharati Vidyapeeth, and St. Xavier's, and collaborated with 17+ governments and foundations to spread career literacy across India.
                            </p>
                            <p className='d-xl-none'>Nikhar has worked with top ad agencies like Ogilvy and DDB Mudra, and raised funds from leading investors and VCs. He has partnered with over 150 educational institutes, including Symbiosis, Bharati Vidyapeeth, and St. Xavier's, and collaborated with 17+ governments and foundations to spread career literacy across India.</p>
                            <p>He has also been fortunate to mentor professionals and students from top B-Schools like ISB, MICA, and IIM.</p>
                            <p>Attend Nikhar’s Masterclass on Landing Your Dream Job to learn how he coaches working professionals to apply smartly by understanding what HRs want.</p> */}
                            <p>
                            Curious about how to land your dream job? Join my Masterclass on Landing Your Dream Job to learn how to apply smartly and understand what HRs are looking for. Let's work together to find the perfect job for you!
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className='d-flex justify-content-center py-5'>
               <div className='career-btn-div mt-0'>
               <a href="/mc2/register" className='btn-theme'>Register Now</a>
               </div>
               </div> */}
            </div>
        </div>
    );
}

export default CareerCoach;
