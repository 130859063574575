import React from 'react'
import TeamMember1 from "../../images/team-member1.png"
import TeamMember2 from "../../images/team-member2.png"
import TeamMember3 from "../../images/team-member3.png"
import TeamMember4 from "../../images/team-member4.png"
import TeamMember5 from "../../images/team-member5.png"
import LinkedInImg from "../../images/linked-in.png"

function TeamMember() {
  return (
    <div className='team-member-div'>
    <div className='container pb-4'>
        <h2 className="main-head"> "Mentoria was a game changer!"</h2>
        <div className="row g-4">
            <div className='col-md-6 col-lg-4 t-m-list-4'>
                <div className='t-m-list-sub'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember1} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a href="https://www.linkedin.com/in/bodhisatta-chatterjee-0ba57a1aa/" target='_blank' className="t-m-heading">Bodhisatta chatterjee</a>
                            <p className='t-m-list-post'>Operations Manager</p>
                        </div>
                        <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                    </div>
                    <p className='t-m-list-content'>My previous company had a toxic environment, and I was desperate to leave. Mentoria guided me through my options and the steps to make a successful switch. Now, my career and mental health have significantly improved!</p>
                </div>
            </div>
            <div className='col-md-6 col-lg-4 t-m-list-4'>
                <div className='t-m-list-sub'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember2} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a href="https://www.linkedin.com/in/vishal-borkar-product-mangement/" target='_blank' className="t-m-heading">Vishal Borkar</a>
                            <p className='t-m-list-post'>Project Manager</p>
                        </div>
                        <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                    </div>
                    <p className='t-m-list-content'>Having tried various roles throughout my career, I needed help to focus and streamline my path. Mentoria guided me on the preparation needed for growth. From my first interaction, I felt confident that I was in the right place and would achieve the right outcome.</p>
                </div>
            </div>
            <div className='col-md-6 col-lg-4 t-m-list-4'>
            <div className='t-m-list-sub'>
                <div className="col d-flex align-items-start">
                    <img className='t-m-profile' src={TeamMember3} width={50} height={50} alt="team member"></img>
                    <div className=' t-m-list-name'>
                        <a href="https://www.linkedin.com/in/supriya-/" target='_blank' className="t-m-heading">supriya Singh</a>
                        <p className='t-m-list-post'>Financial Analyst</p>
                    </div>
                    <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                    </div>
                <p className='t-m-list-content'>My issue was with my career growth. I was stuck and not getting anywhere. This screwed up my confidence. Mentoria has transformed my career and outlook. I am now happily working as a Financial Analyst at one of the top financial services companies in the world.</p>
            </div>
            </div>
            <div className='col-md-6 t-m-list-6'>
                <div className='t-m-list-sub'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember4} width={50} height={50} alt="team member"></img>
                        <div className='t-m-list-name'>
                            <a href="https://www.linkedin.com/in/pranamita-chakravarty-1a291b22a/" target='_blank' className="t-m-heading">Pranamita chakravarty</a>
                            <p className='t-m-list-post'>Fresher</p>
                        </div>
                        <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                    </div>
                    <p className='t-m-list-content'>I was extremely lost before I found out about Mentoria. Their team reassured me that I was on the right path, advised me on how to follow my passion and as well as be secure in my career. I'm extremely happy to say I'm no longer feeling lost. I have re-acquired confidence in myself and have a clear direction on what to do next.</p>
                </div>
            </div>
            <div className='col-md-12 col-lg-6 t-m-list-6'>
                <div className='t-m-list-sub'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember5} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a href="https://www.linkedin.com/in/mishal-m-69169517/" target='_blank' className="t-m-heading">Mishal Menezes</a>
                            <p className='t-m-list-post'>Lead Software Engineer</p>
                        </div>
                        <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                    </div>
                    <p className='t-m-list-content'>I found Mentoria while looking for career help. I had no idea that I would come across a team that takes careers so seriously. Mentoria’s team assisted me with my profile. The team’s counsel on careers was so powerful that I felt relieved after talking with them, and I now have clarity.</p>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default TeamMember;