import React from 'react';
import img from "../../images/Group 3 (1).png";
import img1 from "../../images/3-feature.png";
// import img1 from "../../images/3-feature.png";
import img2 from "../../images/video-online.png";
import Calendar from '../../images/calenerpng.png'
import Clock from '../../images/clock.png'
import Online from '../../images/hour.png'
import { Link, useNavigate } from 'react-router-dom';
function Featured() {
    const navigate = useNavigate();
    return (
        <div className='features-wraper' id="join-masterClass" >
            <div className="container">
                <h2 className="main-head">
                    Join Our Next Masterclass
                </h2>
                <div className="row">
                    <div className="col-lg-6 m-auto">
                    <div className='card-featured pt-md-0 pt-4'>
                            <div className='text-center card-img ' >
                                <img src={img} alt="img" className='img-fluid' width="230" height="230"/>

                            </div>
                            <div className='text-center mt-4'>
                                <div className='online-label'>
                                    <img src={img2} alt="img2" className='img-fluid me-2' width="25" height="25" />
                                    Online</div>
                            </div>
                            <div className='main-head-3 pt-4'>
                                Maximise Happiness Through Career Mastery
                            </div>
                            <div className='banner-list w-100 border-0 justify-content-center py-2'>
                                <div className='banner-list-item'>
                                    <img src={Calendar} width={22} height={22} alt='calendar' className='img-fluid' />
                                   11th August
                                </div>
                                <div className='banner-list-item'>
                                    <img src={Clock} width={22} height={22} alt='Clock' className='img-fluid' />
                                    04:00 PM 
                                </div>
                                <div className='banner-list-item'>
                                    <img src={Online} width={22} height={22} alt='Online' className='img-fluid' />
                                    2 hours 
                                </div>
                            </div>
                            <div className='px-5'>
                                <Link to="/mc1/register" state={{ from: "mc3" }} className="btn-theme">Book Now</Link>
                            </div>
                            <div className='text-center py-3 featured-text'>
                                <p>67% Seats Booked</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 m-auto">
                        <div className='card-featured pt-5 pt-md-0'>
                            <div className='text-center card-img'>
                                <img src={img1} alt="" className='img-fluid' width="280" height="280" />

                            </div>
                            <div className='text-center mt-4'>
                                <div className='online-label'>
                                    <img src={img2} alt="" className='img-fluid me-2' width="25" height="25" />
                                    Online</div>
                            </div>
                            <div className='main-head-3 pt-4'>
                            Land Your Dream Job in the Best Possible Way
                            </div>
                            <div className='banner-list w-100 border-0 justify-content-center py-2'>
                                <div className='banner-list-item'>
                                    <img src={Calendar} width={22} height={22} alt='calendar' className='img-fluid' />
                                    13th October
                                </div>
                                <div className='banner-list-item'>
                                    <img src={Clock} width={22} height={22} alt='Clock' className='img-fluid' />
                                    04:00 PM 
                                </div>
                                <div className='banner-list-item'>
                                    <img src={Online} width={22} height={22} alt='Online' className='img-fluid' />
                                    2 hours 
                                </div>
                            </div>
                            <div className='px-5'>
                                <Link to="/mc2/register" state={{ from: "mc3" }} className="btn-theme">Book Now</Link>
                            </div>
                            <div className='text-center py-3 featured-text'>
                                <p>79% Seats Booked</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Featured;
