import React from 'react';
import Accenture from "../../images/accenture.png"
import Aditya from "../../images/aditya.png"
import Axisbank from "../../images/axis-bank.png"
import Britannia from "../../images/britannia.png"
import Daimler from "../../images/daimler.png"
import Glemark from "../../images/glemark.png"
import Hp from "../../images/hp.png"
import Itc from "../../images/itc.png"
import Laursen from "../../images/laursen.png"
import Myntra from "../../images/myntra.png"
import Reliance from "../../images/reliance.png"
import Pepsico from "../../images/pepsico.png"
import Hdfc from "../../images/hdfc.png"
import Makemytrip from "../../images/makemytrip.png"
import Marico from "../../images/marico.png"
import Tata from "../../images/tata.png"
import Reddyslab from "../../images/reddy.png"
import Adani from "../../images/adani.png"
function CorporatePartners() {
    return (
        <div className='corporate-wrap'>
            <div className="container">
                <h2 className="main-head">
                    Corporate Partners
                </h2>
                <div className="parnters">
                    <div className="parnterimg">
                        <img src={Accenture} alt="Accenture" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Aditya} alt="Aditya" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Axisbank} alt="Axisbank" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Britannia} alt="Britannia" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Daimler} alt="Daimler" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Glemark} alt="Glemark" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Hp} alt="Hp" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Itc} alt="Itc" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Laursen} alt="Laursen" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div> 
                    <div className="parnterimg">
                        <img src={Myntra} alt="Myntra" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Reliance} alt="Reliance" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Pepsico} alt="Pepsico" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Hdfc} alt="Hdfc" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Makemytrip} alt="Makemytrip" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Marico} alt="Marico" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Tata} alt="Tata" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Reddyslab} alt="Reddyslab" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Adani} alt="Adani" className='img-fluid partner-img' 
                        width="117" height="70"/>
                    </div>
                </div>
                <div className='more-link'>
                And Many More
                </div>
            </div>
        </div>
    );
}

export default CorporatePartners;
