import React from 'react';
import Userimg from "../../images/banner_group.png";
import img1 from "../../images/health.png";
import img2 from "../../images/problem.png";
import img3 from "../../images/career.png";
import img4 from "../../images/skill.png";
import img5 from "../../images/calculator.png";
function About() {
    return (
        <div className='about-wrap pt-0 mt-0'>
            <div className="container">
                <h2 className="main-head about-head">
                    India’s Most Anticipated Career Masterclasses
                </h2>
                <div className="aboutwrap-3 card">
                    <div className="row card-body">
                        <div className="col-lg-5 text-center">
                            <img src={Userimg} alt="Userimg" className='img-fluid'
                                width="300" height="300" />
                        </div>
                        <div className="col-lg-7 m-auto">
                            <div className='abtwrap3-tophead'>
                                Maximise Happiness Through Career Mastery
                            </div>
                            <div className='abtwrap3-mainhead'>
                                Feeling stuck in your career? Find out how to break free and achieve growth, happiness, and financial success.
                            </div>
                            <div>
                                <a href="/mc1/register" className='btn-theme w-auto d-inline-block mt-md-5 mt-3 px-5'>Register Now</a>
                            </div>
                        </div>
                        <hr className='my-5' />
                        <div className='row m-0'>
                            <div className="abtwrap3-head3">Exclusive Tools you’ll get</div>
                            <div className="col-lg-12 m-auto">
                                <div className="d-md-flex gap-3 align-items-center justify-content-center flex-wrap-abt">
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img1} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                            Check Your Career Health Score
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img2} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                            Job V/S
                                            Career Problem Checker
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img3} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                            The
                                            Ultimate Career Tracker
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img4} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                            Match My
                                            Skills
                                            Matrix
                                        </div>
                                    </div>
                                    <div className="abt-ex-tools">
                                        <div className='ex-img'>
                                            <img src={img5} alt="img" className='img-fluid' width="120" height="120" />
                                        </div>
                                        <div className='ex-tools'>
                                            Course Value
                                            Calculator
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
