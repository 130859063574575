import React from 'react';
import Userimg from "../../images/user.png";
function Clientsays() {
    return (
        <div className='client-says-wrapper'>
            <div className="container">
                <div className="main-head">
                "Mentoria was a game changer!"
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="clientsay">
                            <div className="clientdata">
                                <div className="clietnimg">
gtht
                                </div>
                                <div className="clientname">
htghtu
                                </div>
                                <div className="clientsocial">
hyyjyju
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="clientsay">
                            vfbfbfgb
                        </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="clientsay">
                            vfbfbfgb
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="clientsay">
                            vfbfbfgb
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="clientsay">
                            vfbfbfgb
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clientsays;
