import React, { useState } from 'react';
import Userimg from "../../images/user.png";
function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div className='faq-wraper'>
            <div className="container">
                <div className='row'>
                    <div className='col-md-12 col-lg-6'>
                        <h2 className="main-head text-start pt-0">
                            Frequently Asked Questions
                        </h2>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={`accordion-button ${activeIndex === 0 ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => toggleAccordion(0)}
                                        aria-expanded={activeIndex === 0}
                                        aria-controls="collapseOne">
                                        What will I gain from attending this Masterclass?
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className={`accordion-collapse collapse ${activeIndex === 0 ? 'show' : ''}`}
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        We'll find out what's the root cause - your job or your career - and give you a treatment plan that will improve your career health within a year. Find out how to plan your next steps and land a dream job that doesn't make you hate Mondays!
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className={`accordion-button ${activeIndex === 1 ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => toggleAccordion(1)}
                                        aria-expanded={activeIndex === 1}
                                        aria-controls="collapseTwo">
                                        Can I ask questions during the Masterclass?
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className={`accordion-collapse collapse ${activeIndex === 1 ? 'show' : ''}`}
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Absolutely! We'll answer all your questions in the last 15 minutes of the session.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className={`accordion-button ${activeIndex === 2 ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => toggleAccordion(2)}
                                        aria-expanded={activeIndex === 2}
                                        aria-controls="collapseThree"
                                    >
                                        Can I invite others to this Masterclass?
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className={`accordion-collapse collapse ${activeIndex === 2 ? 'show' : ''}`}
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Yes, please! You'll get a 'plus-one ticket' to invite a friend, colleague, or family member for free.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className={`accordion-button ${activeIndex === 3 ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => toggleAccordion(3)}
                                        aria-expanded={activeIndex === 3}
                                        aria-controls="collapseThree"
                                    >
                                        What else will I get?
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className={`accordion-collapse collapse ${activeIndex === 3 ? 'show' : ''}`}
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        You'll get five exclusive career growth tools and a surprise gift to boost your career health score. Trust us - you don't want to miss out on this one!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
