import React from 'react';
import Health from "../../images/health.png";
import Problem from "../../images/problem.png";
import Career from "../../images/career.png";
import Skill from "../../images/skill.png";
import Calculator from "../../images/calculator.png";
function CareerTools() {
    return (
        <div className='career-tools-wrapper'>
            <div className="container">
                <div className="main-head col-lg-10 text-center mx-auto">
                Unlock Exclusive Career Growth Tools through this Masterclass
                </div>
               <div className="career-tools">
                <div className="tools">
                    <div className="toolimage">
                        <img src={Health} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Check Your Career Health Score
                    </div>
                    <div className="tooldesc">
                    A comprehensive career health checker that tells you what’s making you hate your job
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Problem} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Job V/S Career Problem Checker
                    </div>
                    <div className="tooldesc">
                    Find out what’s holding you back - your job or your career - before planning your next switch.
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Career} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    The Ultimate Career Tracker
                    </div>
                    <div className="tooldesc">
                    Get our one-year growth plan template to set and achieve your top career goals. The kind of plan your parents will be so proud of!
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Skill} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Match My Skills Matrix
                    </div>
                    <div className="tooldesc">
                    This tool helps you understand which of your skills will work wonders for your next job. And tell you which ones you need to start building.
                    </div>
                </div>
                <div className="tools">
                    <div className="toolimage">
                        <img src={Calculator} alt="Health" className='img-fluid toolimg' width="160" height="180"/>
                    </div>
                    <div className="toolname">
                    Course Value Calculator
                    </div>
                    <div className="tooldesc">
                    Is that additional certificate/degree going to increase your earnings? This tool helps you do the long-term math.
                    </div>
                </div>
               </div>
               <div className='d-flex justify-content-center py-5'>
               <div className='career-btn-div'>
               <a href="/mc1/register" className='btn-theme'>Register Now</a>
               </div>
               </div>
              
            </div>
        </div>
    );
}

export default CareerTools;
