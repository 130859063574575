import React from 'react';
import Img1 from "../../images/bwdisrupt.png"
import Img2 from "../../images/businessline.png"
import Img3 from "../../images/women-en.png"
import Img4 from "../../images/yourstory.png"
function featuredIn() {
    return (
        <div className='client-org-wrap mt-5 bg-white'>
            <div className="container">
                <h2 className="main-head">
                As Featured In
                </h2>
                <div className="parnters-featured pb-5">
                    <div className="parnterimg">
                        <img src={Img4} alt="Img1" className='img-fluid partner-img' 
                        width="160" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Img1} alt="Img1" className='img-fluid partner-img' 
                        width="160" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Img2} alt="Img1" className='img-fluid partner-img' 
                        width="160" height="70"/>
                    </div>
                    <div className="parnterimg">
                        <img src={Img3} alt="Img1" className='img-fluid partner-img' 
                        width="160" height="70"/>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default featuredIn;
