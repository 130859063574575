import React from 'react';
import Calendar from '../../images/calenerpng.png'
import Clock from '../../images/clock.png'
import Online from '../../images/online.png'
import FastFillig from '../../images/fast_filling.png'
import BannerImg from '../../images/banner-3.png'
function Banner() {

    const scrollToElementWithOffset = (id, offset) => {
        const element = document.getElementById(id);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
      
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      };

    const handleRegisterNowClick = () => {
        scrollToElementWithOffset('join-masterClass', -500);
      };
    
  return (
    <div className='banner bg-blue text-white pt-0 banner-new'>
        <div className='container'>
            <div className='row align-items-center flex-column-row'>
                <div className='col-lg-6'>
                    <h1 className='banner-heading'>Unlock Career Happiness and Land Your Dream Job!</h1>
                    <p className='banner-para'>Join Nikhar Arora’s exclusive Masterclasses to discover the secrets of a successful career.</p>
                 
                    <a  onClick={handleRegisterNowClick} className='btn-theme w-auto d-inline-block mt-md-5 mt-3'>Register Now</a>
                  
                </div>
                <div className='col-lg-6'>
                    <img src={BannerImg} className='img-fluid' width="100%" height="100%" alt='banner'/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Banner;
