import React from 'react'
import TeamMember1 from "../../images/team-member1.png"
import TeamMember2 from "../../images/team-member2.png"
import TeamMember3 from "../../images/team-member3.png"
import TeamMember4 from "../../images/team-member-6.png"
import TeamMember5 from "../../images/team-member5.png"
import LinkedInImg from "../../images/linked-in.png"

function TeamMember() {
  return (
    <div className='team-member-div'>
    <div className='container pb-4'>
        <h2 className="main-head"> "Mentoria was a game changer!"</h2>
        <div className="row g-4">
       
            <div className='col-md-6 col-lg-4 t-m-list-4'>
                <div className='t-m-list-sub'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember1} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a href="https://www.linkedin.com/in/bodhisatta-chatterjee-0ba57a1aa/" target='_blank' className="t-m-heading">Bodhisatta chatterjee</a>
                            <p className='t-m-list-post'>Operations Manager</p>
                        </div>
                        <a href="https://www.linkedin.com/in/bodhisatta-chatterjee-0ba57a1aa/" target='_blank' >
                        <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                        </a>
                    </div>
                    <p className='t-m-list-content'>My previous company had a toxic environment, and I needed a way out. Nikhar and his team helped me improve my CV, cover letter, and LinkedIn profile. Thanks to their help, I got a job at Infosys, despite the recession.</p>
                </div>
            </div>
          
            <div className='col-md-6 col-lg-4 t-m-list-4'>
            <div className='t-m-list-sub'>
                <div className="col d-flex align-items-start">
                    <img className='t-m-profile' src={TeamMember3} width={50} height={50} alt="team member"></img>
                    <div className=' t-m-list-name'>
                        <a href="https://www.linkedin.com/in/supriya-/" target='_blank' className="t-m-heading">supriya Singh</a>
                        <p className='t-m-list-post'>Financial Analyst</p>
                    </div>
                    <a href="https://www.linkedin.com/in/supriya-/" target='_blank' >
                    <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                    </a>
                    </div>
                <p className='t-m-list-content'>My issue was with my career growth. I was stuck and not getting anywhere. To make things worse, I had to face constant rejections in interviews. Mentoria has transformed my career and outlook on the job application process. I am now happily working as a Financial Analyst at one of the top financial services companies in the world.</p>
            </div>
            </div>
            <div className='col-md-6 col-lg-4 t-m-list-4'>
                <div className='t-m-list-sub'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember4} width={50} height={50} alt="team member"></img>
                        <div className='t-m-list-name'>
                            <a href="https://www.linkedin.com/in/ravi-kumar-miglani-b4164398/" target='_blank' className="t-m-heading">Ravi Kumar Miglani</a>
                            <p className='t-m-list-post'>Business Analyst</p>
                        </div>
                        <a href="https://www.linkedin.com/in/ravi-kumar-miglani-b4164398/" target='_blank'> <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img></a>
                       
                    </div>
                    <p className='t-m-list-content'>Mentoria has played a key role in my professional growth. Their team has been exceptional in their support from the beginning to the very day that I was able to land a great job offer. They helped me get a clear vision of where I would like to see myself in my professional journey. </p>
                </div>
            </div>
            
            <div className='col-md-6 col-lg-6 t-m-list-6'>
                <div className='t-m-list-sub h-lg-auto h-md-auto'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember5} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a href="https://www.linkedin.com/in/mishal-m-69169517/" target='_blank' className="t-m-heading">Mishal Menezes</a>
                            <p className='t-m-list-post'>Lead Software Engineer</p>
                        </div>
                          <a href="https://www.linkedin.com/in/mishal-m-69169517/" target='_blank'>
                        <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                        </a>
                    </div>
                    <p className='t-m-list-content'>I found Mentoria while looking for job help. I had no idea that I would come across a team that takes careers so seriously. Mentoria’s team assisted me with my profile and CV. The team’s counsel on careers was so powerful that I felt relieved after talking with them, and I now have clarity.</p>
                </div>
            </div>
            <div className='col-md-12 col-lg-6 t-m-list-4'>
                <div className='t-m-list-sub h-auto'>
                    <div className="col d-flex align-items-start">
                        <img className='t-m-profile' src={TeamMember2} width={50} height={50} alt="team member"></img>
                        <div className=' t-m-list-name'>
                            <a href="https://www.linkedin.com/in/vishal-borkar-product-mangement/" target='_blank' className="t-m-heading">Vishal Borkar</a>
                            <p className='t-m-list-post'>Project Manager</p>
                        </div>
                        <a href="https://www.linkedin.com/in/vishal-borkar-product-mangement/" target='_blank' >
                        <img className='t-m-linkedIn' src={LinkedInImg} width={30} height={30} alt="LinkedIn"></img>
                        </a>
                    </div>
                    <p className='t-m-list-content'>Having tried various roles throughout my career, I needed help to focus and streamline my job search. Mentoria guided me with the right job preparation to help me explain my unconventional career past to employers. From my first interaction, I felt confident that I was in the right place and would achieve the right outcome.</p>
                </div>
            </div>
           
        </div>
    </div>
    </div>
  )
}

export default TeamMember;