import React from 'react';
import Logo from '../images/logo.png'
import XImg from '../images/x.png'
import linkedImg from '../images/linkedIn.png'
import instagramImg from '../images/instagram.png'
import facebookImg from '../images/facebook.png'
function Footer() {
  return (
    <div className='footer py-3 py-md-5 py-lg-5'>
      <div className='container'>
        <div className='row justify-content-center pb-3 text-sm-center'>
          <div className='col-md-3 d-md-flex justify-content-start'>
            <img src={Logo} alt='Logo Image' className='img-fluid' width="150" height="150"></img>
          </div> 
          <div className='col-md-9'>
            <nav className="nav justify-content-md-end footer-links mt-4 mt-md-2 nav-center-sm">
              <a className="nav-link" aria-current="page" target='_blank'
               href="https://www.mentoria.com/refund-and-cancellations">Refund & Cancellation</a>
              <a className="nav-link">|</a>
              <a className="nav-link" href="https://www.mentoria.com/privacy-policy" target='_blank'>Privacy Policy</a>
              <a className="nav-link">|</a>
              <a className="nav-link" href="https://www.mentoria.com/terms-and-conditions" target='_blank'>Terms & Condition</a>
            </nav>
          </div>
        </div>
        <hr className='footer-hr'></hr>
        <div className='row justify-content-center pt-3'>
          <div className='col-md-6'>
            <p className='copy-right'>2024 All Rights Reserved</p>
          </div>
          <div className='col-md-6'>
            <nav className="nav justify-content-md-end nav-center-sm">
              <span className="nav-link ps-0">Connect with us</span>
              <a className="nav-link" target="_blank" aria-current="page" 
              href="https://www.facebook.com/mymentoria?mibextid=ZbWKwL">
                <img src={facebookImg} alt="Facebook" width={20} height={20} className="img-fluid"></img>
              </a>
              <a className="nav-link" target="_blank" 
              href="https://www.instagram.com/mymentoria?igsh=YWJyY2tod2I3d2oy">
                <img src={instagramImg} alt="Instagram" width={20} height={20}></img>
              </a>
              <a className="nav-link" target="_blank" 
              href="https://www.linkedin.com/company/mentoria/">
                <img src={linkedImg} alt="Linked In" width={20} height={20}></img>
              </a>
              <a className="nav-link" target="_blank" href="https://x.com/mymentoria">
                <img src={XImg} alt="Icon" width={20} height={20}></img>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
